<template>
  <v-container
    id="create-speaker"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="10"
      >
        <v-form
          method="post"
          ref="formateur"
          @submit.prevent="validate"
        >
          <base-material-card
            color="primary"
          >
            <template v-slot:heading>
              <div
                v-if="!$route.params.id"
                class="display-2 font-weight-light"
              >
                Ajouter un nouveeau formateur
              </div>
              <div
                v-if="$route.params.id"
                class="display-2 font-weight-light"
              >
                Mettre à jour le formateur
              </div>
            </template>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-select
                    outlined
                    v-model="speaker.sexe"
                    :items="titreItems"
                    item-text="titre"
                    item-value="sexe"
                    label="Titre"
                    single-line
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-text-field
                    outlined
                    label="Nom *"
                    v-model="speaker.nom"
                    :rules="[v => !!v || 'Indiquez le nom']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-text-field
                    outlined
                    label="Prénom *"
                    v-model="speaker.prenom"
                    :rules="[v => !!v || 'Indiquez le prénom']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-text-field
                    outlined
                    label="E-mail *"
                    type="email"
                    v-model="speaker.email"
                    :rules="[v => !!v || 'Indiquez l\'adresse email']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    outlined
                    label="Téléphone"
                    type="tel"
                    v-model="speaker.telephone"
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-menu
                    ref="menuDateNaissance"
                    v-model="menuDateNaissance"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="dateNaissanceFormatted"
                        label="Date de naissance"
                        v-bind="attrs"
                        v-on="on"
                        @blur="speaker.dateNaissance = parseDate(dateNaissanceFormatted)"
                        @change="formEdited = true"
                      />
                    </template>
                    <v-date-picker
                      ref="picker"
                      class="mt-0 create-speaker-date-picker"
                      v-model="speaker.dateNaissance"
                      :min="new Date(new Date().setFullYear(new Date().getFullYear() - 100)).toISOString().substring(0, 10)"
                      :max="new Date().toISOString().substring(0, 10)"
                      :picker-date.sync="dateNaissanceDefaut"
                      :first-day-of-week="1"
                      locale="fr"
                      @input="menuDateNaissance = false"
                      @change="saveDateNaissance"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-switch
                    v-model="speaker.interne"
                    label="Formateur interne"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                >
                  <v-autocomplete
                    outlined
                    v-if="!speaker.interne"
                    label="Société"
                    v-model="speaker.societe.id"
                    :items="entreprisesSousTraitantes"
                    item-text="raisonSociale"
                    item-value="id"
                    hint="Laisser vide s'il s'agit d'une nouvelle société"
                    persistent-hint
                    clearable
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-textarea
                    outlined
                    label="Adresse"
                    v-model="speaker.adresse.adressePostale"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                >
                  <v-text-field
                    outlined
                    label="Code postal"
                    v-model="speaker.adresse.codePostal"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                >
                  <v-text-field
                    outlined
                    label="Ville"
                    v-model="speaker.adresse.ville"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                >
                  <v-text-field
                    outlined
                    label="Pays"
                    v-model="speaker.adresse.pays"
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-file-input
                    outlined
                    v-model="cv"
                    accept=".pdf"
                    label="CV"
                    prepend-icon="mdi-file-account"
                    hint="Format accepté : PDF"
                    persistent-hint
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-file-input
                    outlined
                    v-model="photo"
                    accept="image/*"
                    label="Photo"
                    prepend-icon="mdi-camera"
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-autocomplete
                    outlined
                    v-model="competences"
                    label="Compétences"
                    :items="cours"
                    item-text="name"
                    item-value="id"
                    chips
                    deletable-chips
                    clearable
                    multiple
                    @change="formEdited = true"
                  />
                </v-col>
              </v-row>
            </v-container>
          </base-material-card>
          <base-material-card
            v-if="!speaker.interne && !speaker.societe.id"
            color="primary"
          >
            <template v-slot:heading>
              <div class="display-2 font-weight-light">
                Société du formateur
              </div>
            </template>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-btn
                    small
                    class="ma-1"
                    color="grey"
                    plain
                    @click="copierInfos()"
                  >
                    <v-icon
                      class="mr-3"
                    >
                      mdi-content-copy
                    </v-icon>
                    Copier les informations (formateur indépendant)
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="7"
                >
                  <v-text-field
                    outlined
                    label="Raison sociale *"
                    v-model="speaker.societe.raisonSociale"
                    :rules="[v => !!v || 'Indiquez la raison sociale']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-autocomplete
                    outlined
                    label="Forme juridique"
                    v-model="speaker.societe.formeJuridique"
                    :items="['SARL', 'SAS', 'Auto-entrepreneur']"
                    clearable
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-text-field
                    outlined
                    label="E-mail"
                    type="email"
                    v-model="speaker.societe.email"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    outlined
                    label="Téléphone"
                    type="tel"
                    v-model="speaker.societe.telephone"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    outlined
                    label="SIREN"
                    type="number"
                    v-model="speaker.societe.siren"
                    @blur="updateSiret()"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    outlined
                    label="SIRET"
                    type="number"
                    v-model="speaker.societe.siret"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                >
                  <v-textarea
                    outlined
                    label="Adresse"
                    v-model="speaker.societe.adresse.adressePostale"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                >
                  <v-text-field
                    outlined
                    label="Code postal"
                    v-model="speaker.societe.adresse.codePostal"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                >
                  <v-text-field
                    outlined
                    label="Ville"
                    v-model="speaker.societe.adresse.ville"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                >
                  <v-text-field
                    outlined
                    label="Pays"
                    v-model="speaker.societe.adresse.pays"
                    @change="formEdited = true"
                  />
                </v-col>
              </v-row>
            </v-container>
          </base-material-card>
          <v-row>
            <v-col
              cols="12"
              class="text-right"
            >
              <v-btn
                v-if="!$route.params.id"
                color="primary"
                class="mr-0"
                type="submit"
                :disabled="createUpdateDisabled"
              >
                Ajouter le formateur
              </v-btn>
              <v-btn
                v-if="$route.params.id"
                color="primary"
                class="mr-0"
                type="submit"
                :disabled="createUpdateDisabled"
              >
                Mettre à jour le formateur
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {

    data() {
      return {
        formEdited: false,
        entreprisesSousTraitantes: [],
        cours: [],
        competences: [],
        titreItems: [
          {
            titre: 'M.',
            sexe: 'm',
          },
          {
            titre: 'Mme',
            sexe: 'f',
          },
        ],
        speaker: {
          dateNaissance: null,
          competences: [],
          adresse: {},
          societe: {
            raisonSociale: '',
            codeFacture: '',
            type: '',
            adresse: {},
          },
          actif: true,
        },
        dateNaissanceFormatted: '',
        menuDateNaissance: false,
        dateNaissanceDefaut: new Date(new Date().setFullYear(new Date().getFullYear() - 30)).toISOString().substring(0, 10),
        activePicker: null,
        cv: null,
        photo: null,
        createUpdateDisabled: false,
      }
    },

    beforeMount() {
      window.addEventListener('beforeunload', this.preventNav);
    },

    beforeDestroy() {
      window.addEventListener('beforeunload', this.preventNav);
    },

    beforeRouteLeave(to, from, next) {
      if (this.formEdited) {
        if (!window.confirm('Les modifications que vous avez apportées seront perdues, souhaitez-vous quitter la page ?')) {
          return;
        }
      }
      next();
    },

    created() {
      if (this.$route.params.id) {
        // edit mode
        this.axios.get('/intervenants/' + this.$route.params.id)
          .then((res) => {
            this.speaker = res.data;
            this.competences = this.speaker.competences;
            if (!this.speaker.adresse) {
              this.speaker.adresse = {};
            }
            if (!this.speaker.societe) {
              this.speaker.societe = { adresse: {} };
            }
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la récupération du formateur', type: 'warn' });
          });
      }

      this.axios.get('/entreprisesSousTraitantes')
        .then((res) => {
          this.entreprisesSousTraitantes = [...res.data];
          this.entreprisesSousTraitantes.sort((a, b) => a.raisonSociale.localeCompare(b.raisonSociale));
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération des sous-traitants', type: 'warn' });
        });

      this.axios.get('/cours')
        .then((res) => {
          this.cours = [...res.data];
          this.cours.sort((a, b) => a.name.localeCompare(b.name));
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération des cours', type: 'warn' });
        });
    },

    watch: {
      'speaker.dateNaissance'(val) {
        this.dateNaissanceFormatted = this.formatDate(this.speaker.dateNaissance);
        this.menuDateNaissance = false;
      },

      menuDateNaissance (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = this.speaker.dateNaissance ? 'DATE' : 'YEAR'))
      },
    },

    methods: {
      validate () {
        this.formEdited = false;
        if (this.$refs.formateur.validate()) {
          if (this.$route.params.id) {
            // edit mode
            this.updateSpeaker();
          } else {
            // create mode
            this.createSpeaker();
          }
        } else {
          this.setSnack({ text: 'Veuillez renseigner tous les champs obligatoires', type: 'warn' });
        }
      },

      createSpeaker() {
        this.createUpdateDisabled = true;
        this.competences.forEach(c => {
          this.speaker.competences.push({ id: c });
        });

        if (this.speaker.interne) {
          this.speaker.societe = null;
        }

        this.speaker.formations = [];

        let formData;

        this.axios.post('/intervenants', this.speaker)
          .then((res) => {
            if (this.photo) {
              formData = new FormData();
              formData.append('file', this.photo);
              this.axios.patch('/intervenants/' + res.data.id + '/uploadPhoto', formData, { headers: { 'content-type': 'multipart/form-data' } });
            }
            return res;
          })
          .then((res) => {
            if (this.cv) {
              formData = new FormData();
              formData.append('file', this.cv);
              this.axios.patch('/intervenants/' + res.data.id + '/uploadCv', formData, { headers: { 'content-type': 'multipart/form-data' } });
            }
            return res;
          })
          .then((res) => {
            this.createUpdateDisabled = false;
            this.$router.push({ name: 'FormateurDetail', params: { id: res.data.id } });
            this.setSnack({ text: 'Le formateur a bien été ajouté', type: 'success' });
          })
          .catch(e => {
            this.createUpdateDisabled = false;
            this.setSnack({ text: 'Erreur lors de l\'ajout du nouveau formateur ', type: 'warn' });
          });
      },

      updateSpeaker() {
        this.createUpdateDisabled = true;
        if (this.competences !== this.speaker.competences) {
          this.speaker.competences = [];
          this.competences.forEach(c => {
            this.speaker.competences.push({ id: c });
          });
        }

        if (this.speaker.interne) {
          this.speaker.societe = null;
        } else {
          this.speaker.societe = { id: this.speaker.societe.id };
        }

        let formData;

        delete this.speaker.formations;

        this.axios.patch('/intervenants/' + this.speaker.id + '/updateInfos', this.speaker)
          .then((res) => {
            if (this.photo) {
              formData = new FormData();
              formData.append('file', this.photo);
              return this.axios.patch('/intervenants/' + this.speaker.id + '/uploadPhoto', formData, { headers: { 'content-type': 'multipart/form-data' } });
            }
            return this.speaker;
          })
          .then((res) => {
            if (this.cv) {
              formData = new FormData();
              formData.append('file', this.cv);
              return this.axios.patch('/intervenants/' + this.speaker.id + '/uploadCv', formData, { headers: { 'content-type': 'multipart/form-data' } });
            }
            return this.speaker;
          })
          .then((res) => {
            this.createUpdateDisabled = false;
            this.setSnack({ text: 'Le formateur a bien été mis à jour', type: 'success' });
            this.$router.push({ name: 'FormateurDetail', params: { id: this.speaker.id } });
          })
          .catch(e => {
            this.createUpdateDisabled = false;
            this.setSnack({ text: 'Erreur lors de la mise à jour du formateur', type: 'warn' });
          });
      },

      copierInfos() {
        this.speaker.societe.raisonSociale = '';
        if (this.speaker.sexe === 'm') {
          this.speaker.societe.raisonSociale += 'M. ';
        } else if (this.speaker.sexe === 'f') {
          this.speaker.societe.raisonSociale += 'Mme ';
        }
        this.speaker.societe.raisonSociale += this.speaker.nom + ' ' + this.speaker.prenom;
        this.speaker.societe.email = this.speaker.email;
        this.speaker.societe.telephone = this.speaker.telephone;
        this.speaker.societe.adresse = this.speaker.adresse;
      },

      updateSiret() {
        this.speaker.societe.siret = this.speaker.societe.siren;
        this.$forceUpdate();
      },

      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },

      parseDate (date) {
        if (!date) return null

        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },

      saveDateNaissance (date) {
        this.$refs.menuDateNaissance.save(date)
      },

      preventNav(event) {
        if (this.formEdited) {
          event.preventDefault();
          event.returnValue = '';
          this.formEdited = false;
        }
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },
  }
</script>
